import React, {useEffect} from "react"
import { navigate } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const VerifyEmail = ({ token }) => {
  
  const verifyToken = async () => {
    fetch(`${process.env.GATSBY_BASE_API_URL}/api/v1/email/verify`,
          {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify( {token} )
          }
      ).then( async response => {
          if(response.ok)
            navigate('/public/verified')
          const res = await response.json()
          if(res.errors)
            window.confirm(res.errors[0])
            navigate('/')
      },
      networkError => {
          console.log(networkError.message);
      });
  }

  useEffect(() => {
    if (token) {
      verifyToken();
    }
  }, [token])

  return <Layout>
    	<SEO title="Verify Email" />
    <div className="text-center page-404 d-flex flex-column align-items-center justify-content-center">
      <h1>Verifying Email Address....</h1>
   </div>
  	</Layout>
}

export default VerifyEmail
